










































import { Component, Vue } from 'vue-property-decorator';
import vSelect from 'vue-select';

@Component({
    components: {
        vSelect
    }
})
export default class CustomerTaskList extends Vue {
    searchTerm = '';
    showFilterModal = false;
    selectedStatus = '-- Tất cả trạng thái --';
    data = [];
    currentx = 10;
}
